.shadow-lightgreen {
  box-shadow: 0 0.5rem 1rem lightgreen;
  background-color: rgba(0, 0, 0, 0.15) !important;
  border-block: 1px solid lightgreen; 
}
.text-off-white
{
  color: #F8F0E3;
}
.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, 0.06);
}